import MasterServices from "@/apiServices/MasterServices";
import FoodServices from "@/apiServices/FoodServices";

export default {
  namespaced: true,
  state: {
    recipe_masters_list: [],
    recipe_mains_list: [],
    ingredient_masters_list: [],
    ingredient_mains_list: [],
    item_masters_list: [],
    ingredient_category_list: [],
    food_icons: [],
    food_actions: [],
  },
  getters: {
    getAllRecipeMaster: (state) => state.recipe_masters_list,
    getAllRecipeMain: (state) => state.recipe_mains_list,
    getAllIngredientMaster: (state) => state.ingredient_masters_list,
    getAllIngredientMain: (state) => state.ingredient_mains_list,
    getAllItemMasterList: (state) => state.item_masters_list,
    getAllIngredientCategory: (state) => state.ingredient_category_list,
    getAllFoodIcons: (state) => state.food_icons,
    getAllFoodActions: (state) => state.food_actions,
  },
  mutations: {
    SET_RECIPE_MASTER: (state, val) => {
      state.recipe_masters_list = val;
    },
    SET_RECIPE_MAIN: (state, val) => {
      state.recipe_mains_list = val;
    },
    SET_INGREDIENT_MASTER: (state, val) => {
      state.ingredient_masters_list = val;
    },
    SET_INGREDIENT_MAIN: (state, val) => {
      state.ingredient_mains_list = val;
    },
    SET_ITEM_MASTERS: (state, val) => {
      state.item_masters_list = val;
    },
    SET_INGREDIENT_CATEGORY_MASTERS: (state, val) => {
      state.ingredient_category_list = val;
    },
    SET_FOOD_ICONS: (state, val) => {
      state.food_icons = val;
    },
    SET_FOOD_ACTIONS: (state, val) => {
      state.food_actions = val;
    },
  },
  actions: {
    setRecipeMaster: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_RECIPE_MASTER", []);
      FoodServices.getAllRecipeMaster()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_RECIPE_MASTER", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting RecipeMaster", error);
        });
    },
    setRecipeMain: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_RECIPE_MAIN", []);
      FoodServices.getAllRecipeMain()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_RECIPE_MAIN", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting RecipeMain", error);
        });
    },
    setIngredientMaster: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_INGREDIENT_MASTER", []);
      FoodServices.getAllIngredientMaster()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_INGREDIENT_MASTER", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting IngredientMaster", error);
        });
    },
    setIngredientMain: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_INGREDIENT_MAIN", []);
      FoodServices.getAllIngredientMain()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_INGREDIENT_MAIN", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting IngredientMain", error);
        });
    },
    setItemMaster: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_ITEM_MASTERS", []);
      FoodServices.getAllItem()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_ITEM_MASTERS", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting ItemMaster", error);
        });
    },
    setIngredientCategoryMaster: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_INGREDIENT_CATEGORY_MASTERS", []);
      FoodServices.getAllIngredientCatMaster()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_INGREDIENT_CATEGORY_MASTERS", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting getAllIngredientCatMaster", error);
        });
    },
    setFoodIcons: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_FOOD_ICONS", []);
      FoodServices.getAllIcons(payload)
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_FOOD_ICONS", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting food icons", error);
        });
    },
    setFoodActions: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_FOOD_ACTIONS", []);
      FoodServices.getAllFoodActions()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_FOOD_ACTIONS", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting food Actions", error);
        });
    },
  },
};
