import store from "@/store";
import axios from "axios";
import AxiosIns from "./AxiosIns";
import { TokenService } from "./StorageService";

const AuthServices = {

    async Login(payload) {
        try {
            const res = await AxiosIns().post('auth/dashboard-login', payload);
            if (res && res.data.status) {
                const { refreshToken } = res.data.data;
                TokenService.saveRefreshToken(refreshToken);
                const saveAccessToken = await this.GetAndSetAccessToken();
                if (!saveAccessToken) return null;
                return true;
            } else {
                return res;
            }
        } catch (error) {
            console.log('Error in Login Api ', error);
            return null;
        }
    },
    async GetAndSetAccessToken() {
        try {
            const refreshToken = TokenService.getRefreshToken();
            if (!refreshToken) {
                console.log('Refresh Token not present ', refreshToken);
                // window.location.replace('/');
                return null;
            }
            const res = await axios.get(`${process.env.VUE_APP_BASEURL}/auth/access-token`, {
                headers: {
                    authorization: refreshToken
                }
            });
            if (res && res.data.status) {
                const { accessToken } = res.data.data;
                store.dispatch("user/setUserDetails");
                TokenService.saveAccessToken(accessToken);
                return true;
            } else {
                TokenService.removeRefreshToken();
                TokenService.removeAccessToken();
                // window.location.replace('/');
                return null;
            }
        } catch (error) {
            console.log('Error in getting Access Token ', error);
            TokenService.removeAccessToken();
            TokenService.removeRefreshToken();
            // window.location.replace('/');
            return null;
        }
    }

};

export default AuthServices;