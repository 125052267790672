
export default {

    "LoginPage": {
        "Login": "Acceso!",
        "Onceyouget": "Una vez que nos conozcas, no puedes alejarte de nosotras!",
        "Password": "Clave",
        "name": "nombre",
        "ForgotPassword": "Has olvidado tu contraseña?",
        "Signin": "Iniciar sesión",
        "Createaccount": "Crea una cuenta"
    },

    "RecipeMaster": {
        "Recipe Id": "ID DE RECETA",
        "Recipe Master List": "Lista maestra de recetas",
        "RecipeMasterList": "Lista maestra de recetas",
        "Add Recipe": "Agregar Receta",
        "Recipe Name": "Nombre de la receta",
        "Recipe Master Form": "Formulario maestro de recetas",
        "Recipe Code": "Código de receta",
        "Cuisine Name": "Nombre de la cocina",
        "Country Name": "Nombre del país",
        "Recipe Description": "Descripción de la receta"
    },

    "RecipeMainMaster": {
        "RecipeMainMaster": "Lista principal de recetas",
        "Recipe Main Form": "Formulario principal de receta",
        "RECIPEID": "ID DE RECETA",
        "RECIPENAME": "NOMBRE DE LA RECETA",
        "RECIPE CODE": "CÓDIGO DE RECETA",
        "Recipe Master List": "Lista maestra de recetas",
        "AddRecipe": "Agregar Receta",
        "Recipe Name": "Nombre de la receta",
        "Recipe Code": "Código de receta",
        "Recipe SS ID": "ID de SS de receta",
        "Select Recipe Master": "Seleccionar maestro de recetas",
        "Language": "Nombre del lenguaje",
        "Country": "País",
        "Total Time": "Tiempo Total",
        "Cook Time": "Hora de cocinar",
        "Prep Time": "Deberes Tiempo",
        "Recipe Portions": "Receta Porciones",
        "Recipe Calories": "calorías de la receta",
        "Recipe Carb Content": "Contenido de carbohidratos de la receta",
        "Recipe Fat Content": "Contenido de grasa de la receta",
        "Recipe Protein Content": "Contenido de proteínas de la receta",
        "Language Category": "Categoría de idioma",
        "Recipe Type": "Receta Tipo",
        "Recipe Type 1": "Receta Tipo 1",
        "Recipe Type 2": "Receta Tipo 2",
        "Veg": "verduras",
        "Lactose Friendly": "Amigable con la lactosa",
        "Gluten Friendly": "Sin gluten",
        "Peanut Friendly": "Amigable con maní",
        "Chicken": "Pollo",
        "Turkey": "Pava",
        "Pork": "Cerda",
        "Lamb": "Cordera",
        "Beef": "Carne de res",
        "Fish": "Pez",
        "Egg": "Huevo",
        "Legume": "Legumbre",
        "Milk Heavy": "leche pesada",
        "Carb Heavy": "Carbohidratos pesados",
        "Nuts Heavy": "frutos secos pesados",
        "Veggie Heavy": "vegetariano pesado",

        "Meat": "Carne",
        "Red Meat": "Carne roja",
        "Other Meat": "Otras carnes",
        "Vegan": "Vegano",

        "SN":"número de serie",
        "Ingredient":"Ingrediente",
        "Quantity":"Cantidad",
        "Unit":"Unidad",
        "Action":"Acción"
    },

    "Ingredient Master List": {
        "IngredientMaster": "Lista maestra de ingredientes",
        "INGREDIENTID": "IDENTIFICACIÓN DEL INGREDIENTE",
        "INGREDIENTCODE": "CÓDIGO DE INGREDIENTE",
        "Ingredient Form": "Forma de ingrediente",
        "Ingredient Main Form": "Formulario principal de ingredientes",
        "AddIngredient": "Agregar ingrediente",
        "Ingredient Name": "Nombre del ingrediente",
        "Ingredient Code": "Código de ingrediente",
        "Cuisine Name": "Nombre de la cocina",
        "Country Name": "Nombre del país",
        "Unit Name": "Nombre de la unidad",
        "Ingredient Description": "Descripción del ingrediente"
    },

    "IngredientMainMaster": {
        "Ingredient Main Master": "Lista maestra principal de ingredientes",
        "IngredientMaster": "Lista maestra de ingredientes",
        "INGREDIENTID": "INGREDIENT ID",
        "Recipe Ingredient Form": "Formulario de ingredientes de recetas",
        "INGREDIENTNAME": "IDENTIFICACIÓN DEL INGREDIENTE",
        "INGREDIENTCODE": "CÓDIGO DE INGREDIENTE",
        "Ingredient Main Name": "Nombre principal del ingrediente",
        "Ingredient Code": "Código de ingrediente",
        "Ingredient Name": "Nombre del ingrediente",
        "Country Name": "Nombre del país",
        "Language Name": "Nombre del lenguaje",
        "Ingredient Category":"Categoría de ingrediente",
        "Ingredient Form":"Categoría de ingrediente",
        "AddIngredient":"Añadir ingrediente"
    },
    "RecipeIngredient": {
        "Recipe Ingredient List": "Lista de ingredientes de la receta",
        "RECIPEINGREDIENTID": "IDENTIFICACIÓN DEL INGREDIENTE DE LA RECETA",
        "MAININGREDIENTNAME": "NOMBRE DEL INGREDIENTE PRINCIPAL",
        "MAINRECIPE NAME": "NOMBRE DE LA RECETA PRINCIPAL",
        "LANGUNITNAME": "IDIOMA NOMBRE DE LA UNIDAD",
        "UNITSQUANTITY": "UNIDADES CANTIDAD",
        "Add Recipe Ingredient": "Agregar ingrediente de receta",
        "Recipe Ingredient Form": "Formulario de ingredientes de recetas",
        "Ingredient Item Form": "Formulario de elemento de ingrediente",
        "Main Ingredient Name": "Nombre del ingrediente principal",
        "Main Recipe Name": "Nombre de la receta principal",
        "Language Unit Name": "Nombre de la unidad de idioma",
        "Units Quantity": "Unidades Cantidad"
    },

    "Ingredient Item List": {
        "IngredientItemList": "Lista de elementos de ingredientes",
        "INGREDIENT ITEM ID": "IDENTIFICACIÓN DEL ARTÍCULO DEL INGREDIENTE",
        "ITEM NAME": "NOMBRE DEL ÁRTICULO",
        "MAIN INGREDIENT NAME": "NOMBRE DEL INGREDIENTE PRINCIPAL",
        "LANG. UNIT NAME": "IDIOMA NOMBRE DE LA UNIDAD",
        "UNITS QUANTITY": "UNIDADES CANTIDAD",
        "Add Ingredient Item": "Agregar elemento de ingrediente",
        "Main Ingredient Name": "Nombre del ingrediente principal",
        "Item Name": "Nombre del árticulo",
        "Language Unit Name": "Nombre de la unidad de idioma",
        "Units Quantity": "Unidades Cantidad"
    },

    "ItemList": {
        "Item List": "Lista de articulos",
        "ITEM ID": "IDENTIFICACIÓN DEL",
        "ITEM NAME": "NOMBRE DEL ÁRTICULO",
        "ITEM URL": "UR DEL ARTÍCULOL",
        "Add Item": "Añadir artículo",
        "Item Form": "Formulario de artículo",
        "Store Name": "Nombre de la tienda",
        "Item Name": "Nombre del árticulo",
        "Item Url": "URL del artículo",
        "Item Ean 13": "Artículo Ean 13",
        "Item Catalogref": "Catálogo de artículosref",
        "Item SKU Id": "Código SKU del artículo",
        "Main Ingredient Name": "Nombre del ingrediente principal",
        "Lang. Format": "Idioma Formato",
        "Lang. Unit Name": "Idioma Nombre de la unidad",
        "Item Units Quantity": "Artículo Unidades Cantidad",
        "Currency": "Divisa",
        "Item Price": "Precio del articulo"
    },

    "Ingredient Item List": {
        "IngredientItemList": "Lista de elementos de ingredientes",
        "INGREDIENT ITEM ID": "IDENTIFICACIÓN DEL ARTÍCULO DEL INGREDIENTE",
        "ITEM NAME": "NOMBRE DEL ÁRTICULO",
        "MAIN INGREDIENT NAME": "NOMBRE DEL INGREDIENTE PRINCIPAL",
        "LANG. UNIT NAME": "IDIOMA NOMBRE DE LA UNIDAD",
        "UNITS QUANTITY": "UNIDADES CANTIDAD",
        "Add Ingredient Item": "Agregar elemento de ingrediente",





        "Main Ingredient Name": "Nombre del ingrediente principal",
        "Item Name": "Nombre del árticulo",
        "Language Unit Name": "Nombre de la unidad de idioma",
        "Units Quantity": "Unidades Cantidad"
    },
    "ItemList": {
        "Item List": "Lista de articulos",
        "ITEM ID": "IDENTIFICACIÓN DEL",
        "ITEM NAME": "NOMBRE DEL ÁRTICULO",
        "ITEM URL": "UR DEL ARTÍCULOL",
        "Add Item": "Añadir artículo",
        "Item Form": "Formulario de artículo",
        "Store Name": "Nombre de la tienda",
        "Item Name": "Nombre del árticulo",
        "Item Url": "URL del artículo",
        "Item Ean 13": "Artículo Ean 13",
        "Item Catalogref": "Catálogo de artículosref",
        "Item SKU Id": "Código SKU del artículo",
        "Main Ingredient Name": "Nombre del ingrediente principal",
        "Lang. Format": "Idioma Formato",
        "Lang. Unit Name": "Idioma Nombre de la unidad",
        "Item Units Quantity": "Artículo Unidades Cantidad",
        "Currency": "Divisa",
        "Item Price": "Precio del articulo"
    },
    "Language List": {
        "LANGUAGE ID": "ID DE IDIOMA",
        "LANGUAGE": "IDIOMA",
        "LANGUAGE CODE": "CÓDIGO DE LENGUAJE",
        "Add Lang": "Agregar idioma",
        "Add Category": "añadir categoría",
        "Category Id": "Categoria ID",
        "Category Name": "nombre de la categoría",
        "Category Code": "Código de categoría",
        "Category Description": "Descripción de categoría"
    },
    "Country Master List": {
        "Country Master List": "Lista de países",
        "COUNTRY ID": "IDENTIFICACIÓN DEL PAÍS",
        "COUNTRY NAME": "NOMBRE DEL PAÍS",
        "COUNTRY CODE": "CÓDIGO DE PAÍS",
        "Country ISD Code": "Código ISD del país",
        "Add Country": "Agregar país",
        "Country Name": "Nombre del país",
        "Country Code": "Código de país"
    },
    "Cuisine Master List": {
        "Cuisine Master List": "Lista maestra de cocina",
        "CUISINE ID": "ID DE GASTRONOMÍA",
        "CUISINE NAME": "NOMBRE DE LA GASTRONOMÍA",
        "CUISINE CODE": "CÓDIGO DE COCINA",
        "Add Cuisine": "Agregar cocina",
        "Cuisine Name": "Nombre de la cocina",
        "Cuisine Code": "Código de cocina",
        "Cuisine Description": "Descripción de la cocina"

    },
    "Currency Master List": {
        "Currency Master List": "Lista maestra de divisas",
        "CURRENCY ID": "ID DE MONEDA",
        "CURRENCY CODE": "CÓDIGO DE MONEDA",
        "Add Currency": "Agregar moneda",
        "Currency Name": "Divisa",
        "Currency Code": "Código de moneda",
        "Country Name": "Nombre del país"
    },
    "Unit Master List": {
        "Unit Master List": "Lista maestra de unidades",
        "UNIT ID": "ID DE LA UNIDAD",
        "UNIT NAME": "NOMBRE DE LA UNIDAD",
        "UNIT CODE": "CODIGO DE UNIDAD",
        "Add Unit": "Agregar unidad",
        "Unit Name": "Nombre de la unidad",
        "Unit Code": "Codigo de unidad",
        "Unit Description": "Descripción de la unidad"
    },
    "Format Master List": {
        "Format Master List": "Formato de lista maestra",
        "FORMAT ID": "ID DE FORMATO",
        "FORMAT NAME": "NOMBRE DEL FORMATO",
        "FORMAT CODE": "CÓDIGO DE FORMATO",
        "Add Format": "Agregar formato",
        "Format Name": "Nombre del formato",
        "Format Code": "Código de formato",
        "Format Description": "Descripción del formato"
    },
    "Language Format Master List": {
        "Language Format Master List": "Lista maestra de formato de idioma",
        "LANGUAGE FORMAT ID": "ID DE FORMATO DE IDIOMA",
        "LANGUAGE FORMAT NAME": "IDIOMA FORMATO NOMBRE",
        "LANGUAGE FORMAT CODE": "CÓDIGO DE FORMATO DE IDIOMA",
        "Add Lang": "Agregar IDIOMA",
        "Language Format Name": "Idioma Formato Nombre",
        "Language Format Code": "Código de formato de idioma",
        "Language Name": "Nombre del lenguaje",
        "Language Format Description": "Idioma Formato Descripción"
    },
    "Language Unit Master List": {
        "Language Unit Master": "Lista maestra de unidades lingüísticas",
        "LANGUAGE UNIT ID": "ID DE UNIDAD DE IDIOMA",
        "LANGUAGE UNIT NAME": "NOMBRE DE LA UNIDAD DE IDIOMA",
        "LANGUAGE UNIT CODE": "CÓDIGO DE UNIDAD DE IDIOMA",
        
        "Add Lang": "Agregar IDIOMA",
        "Language Name": "Nombre del lenguaje",
        "Language Unit Description": "Idioma Formato Descripción"

    },
    "Category Master List": {
        "Category Master List": "Lista maestra de categorías",
        "CATEGORY ID": "CATEGORIA ID",
        "CATEGORY NAME": "NOMBRE DE LA CATEGORÍA",
        "CATEGORY CODE": "CÓDIGO DE CATEGORÍA",
        "Add Category": "añadir categoría",
        "Category Name": "nombre de la categoría",
        "Category Code": "Código de categoría",
        "Category Description": "Descripción de categoría"
    },
    "Language Category Master List": {
        "Language Category Master List": "Lista maestra de categorías de idiomas",
        "LANGCATEGORY ID": "ID DE CATEGORÍA DE IDIOMA",
        "LANGCATEGORY NAME": "NOMBRE DE CATEGORÍA DE IDIOMA",
        "LANGCATEGORY CODE": "CÓDIGO DE CATEGORÍA DE IDIOMA",
        "Add LangCategory": "Agregar categoría de idioma",
        "LangCategory Name": "Idioma Nombre de categoría",
        "LangCategory Code": "Código de categoría de idioma",
        "Language Name": "Nombre del lenguaje",
        "LangCategory Description": "IdiomaCategoría Descripción"
    },
    "Retailer Master List": {
        "Retailer Master List": "Lista maestra de minoristas",
        "RETAILER ID": "IDENTIFICACIÓN DEL MINORISTA"
        , "RETAILER NAME": "NOMBRE DEL MINORISTA",
        "RETAILER CODE": "CÓDIGO DE DISTRIBUIDOR",
        "Add Retailer": "Agregar minorista",
        "Retailer Name": "NOMBRE DEL MINORISTA",
        "Retailer Code": "Código de minorista",
        "Retailer URL": "URL del minorista"
    },
    "Store Master List": {
        "STORE ID": "ID DE LA TIENDA",
        "Store Master List": "Lista maestra de tiendas",
        "STORE NAME": "NOMBRE DE LA TIENDA",
        "STORE CODE": "CÓDIGO DE TIENDA",
        "Add Store": "Agregar tienda",
        "Store Name": "Nombre de la tienda",
        "Store Code": "CÓDIGO DE TIENDA",
        "Retailer Name": "Nombre del minorista",
        "Country Name": "Nombre del país",
        "Store URL": "URL de la tienda"
    },
    "Users": {
        "Users": "Users",
        "NAME": "NAME",
        "EMAIL": "EMAIL",
        "ROLE": "ROLE",
        "MOBILE": "MOBILE",
        "CITY": "CITY",
        "USER NAME": "USER NAME",
        "ROLE KEY": "ROLE KEY"
    },
    "Common": {
        "Back": "atrás",
        "Save": "Ahorrar",
        "Status": "ESTADO",
        "Edit": "EDITAR",
        "Search": "Búsqueda"
    },
    "IngredientCategoryMaster":{
        "AddIngredientCategory":"Agregar categoría de ingrediente",
        "INGREDIENTCATEGORYID":"ID DE CATEGORÍA DE INGREDIENTE",
        "INGREDIENTCATEGORYNAME":"NOMBRE DE LA CATEGORÍA DEL INGREDIENTE",
        "INGREDIENTCATEGORYCODE":"CÓDIGO DE CATEGORÍA DE INGREDIENTE",
        "Ingredient Category Name":"Nombre de categoría de ingrediente",
        "Ingredient Category Code":"Código de categoría de ingrediente",
        "Description":"Descripción",
        "Ingredient Category Form":"Formulario de categoría de ingrediente"
    },
    "Sidebar": {
        "Home": "Hogar",
        "Food": "Alimento",
        "RecipeMaster": "Maestro de recetas",
        "RecipeMain": "Receta principal",
        "Ingredient": "Ingrediente",
        "IngredientMain": "Ingrediente Principal",
        "RecipeIngredient": "Ingrediente de receta",
        "IngredientItem": "Artículo de ingrediente",
        "Item": "Artículo",
        "Masters": "Maestras",
        "IngredientMaster": "Maestro de ingredientes",
        "IngredientCategory":"Categoría de ingrediente",
        "Language Master List": "Lista de idiomas",
        "Language Master Form": "Formulario de idioma",
        "Language Category Master List": "Lista de categorías de idioma",
        "Language Category Master Form": "Formulario de categoría de idioma",
        "Country Master List": "Lista de países",
        "Country Master Form": "Formulario de país",
        "Cuisine Master List": "Lista de cocina",
        "Cuisine Master Form": "Formulario de cocina",
        "Currency Master List": "Lista de divisas",
        "Currency Master Form": "Formulario de moneda",
        "Unit Master List": "Lista de unidades",
        "Unit Master Form": "Formulario de unidad",
        "Format Master List": "Formato de lista maestra",
        "Format Master Form": "Formato de formulario maestro",
        "Language Format Master List": "Lista de formatos de idioma",
        "Language Format Master Form": "Formulario de formato de idioma",
        "Language Unit Master List": "Lista de unidades de idioma",
        "Language Unit Master Form": "Formulario de unidad de idioma",
        "Category Master List": "Lista de categoría",
        "Category Master Form": "Formulario de categoría",
        "Retailer Master List": "Lista de minoristas",
        "Retailer Master Form": "Formulario de minorista",
        "Store Master List": "Lista maestra de tiendas",
        "Store Master Form": "Almacenar formulario maestro",
        "Language": "Idiomas",
        "Country": " Del país",
        "Cuisine": "Cocina",
        "Currency": "Divisas",
        "Unit": "Unidad",
        "Format": "Formato",
        "LanguageFormat": "Formato de idioma",
        "LanguageUnit": "Unidad de Lengua",
        "Category": "Categoría",
        "LanguageCategory": " La categoría de idioma",
        "Retailer": "Maestro minorista",
        "Store": " La tienda",
        "Users": "Usuarias",
        "User": "Usuaria"
    },
    "img Upload":{
        "Upload a image of":"Sube una imagen de",
        "pixel":"píxel",
        "Upload Image":"Cargar imagen",
        "Delete":"Borrar",
        "Crop":"Cultivo",
        "Upload":"Subir",
        "No File Choesen":"Ningún archivo elegido"
    }
}