import AxiosIns from "./AxiosIns";

const MasterServices = {

    async commonFileUpload(payload) {
        const response = await AxiosIns().post(`dashboard/masters/file-upload`, payload);
        return response;
    },
    async commonFileRemove(payload) {
        const response = await AxiosIns().get(`dashboard/masters/file-remove`, { params: payload });
        return response;
    },

    // Language Masters Start
    async getAllLanguages() {
        const response = await AxiosIns().get(`dashboard/masters/language`);
        return response;
    },

    async getSingleLanguage(payload) {
        const response = await AxiosIns().get(`dashboard/masters/language/${payload}`);
        return response;
    },

    async saveLanguage(payload) {
        const response = await AxiosIns().post(`dashboard/masters/language-save`, payload);
        return response;
    },

    async setLanguageStatus(payload) {
        const response = await AxiosIns().post(`dashboard/masters/language-status`, payload);
        return response;
    },
    // Language Masters End

    // Country Masters Start
    async getAllCountry() {
        const response = await AxiosIns().get(`dashboard/masters/country`);
        return response;
    },

    async getSingleCountry(payload) {
        const response = await AxiosIns().get(`dashboard/masters/country/${payload}`);
        return response;
    },

    async saveCountry(payload) {
        const response = await AxiosIns().post(`dashboard/masters/country-save`, payload);
        return response;
    },

    async setCountryStatus(payload) {
        const response = await AxiosIns().post(`dashboard/masters/country-status`, payload);
        return response;
    },
    // Country Masters End

    // Cuisine Masters Start
    async getAllCuisine() {
        const response = await AxiosIns().get(`dashboard/masters/cuisine`);
        return response;
    },

    async getSingleCuisine(payload) {
        const response = await AxiosIns().get(`dashboard/masters/cuisine/${payload}`);
        return response;
    },

    async saveCuisine(payload) {
        const response = await AxiosIns().post(`dashboard/masters/cuisine-save`, payload);
        return response;
    },

    async setCuisineStatus(payload) {
        const response = await AxiosIns().post(`dashboard/masters/cuisine-status`, payload);
        return response;
    },
    // Cuisine Masters End

    // Currency Masters Start
    async getAllCurrency() {
        const response = await AxiosIns().get(`dashboard/masters/currency`);
        return response;
    },

    async getSingleCurrency(payload) {
        const response = await AxiosIns().get(`dashboard/masters/currency/${payload}`);
        return response;
    },

    async saveCurrency(payload) {
        const response = await AxiosIns().post(`dashboard/masters/currency-save`, payload);
        return response;
    },

    async setCurrencyStatus(payload) {
        const response = await AxiosIns().post(`dashboard/masters/currency-status`, payload);
        return response;
    },
    // Currency Masters End

    // Unit Masters Start
    async getAllUnit() {
        const response = await AxiosIns().get(`dashboard/masters/unit`);
        return response;
    },

    async getSingleUnit(payload) {
        const response = await AxiosIns().get(`dashboard/masters/unit/${payload}`);
        return response;
    },

    async saveUnit(payload) {
        const response = await AxiosIns().post(`dashboard/masters/unit-save`, payload);
        return response;
    },

    async setUnitStatus(payload) {
        const response = await AxiosIns().post(`dashboard/masters/unit-status`, payload);
        return response;
    },
    // Unit Masters End

    // Format Masters Start
    async getAllFormat() {
        const response = await AxiosIns().get(`dashboard/masters/format`);
        return response;
    },

    async getSingleFormat(payload) {
        const response = await AxiosIns().get(`dashboard/masters/format/${payload}`);
        return response;
    },

    async saveFormat(payload) {
        const response = await AxiosIns().post(`dashboard/masters/format-save`, payload);
        return response;
    },

    async setFormatStatus(payload) {
        const response = await AxiosIns().post(`dashboard/masters/format-status`, payload);
        return response;
    },
    // Format Masters End

    // Langunit Masters Start
    async getAllLangunit() {
        const response = await AxiosIns().get(`dashboard/masters/langunit`);
        return response;
    },

    async getSingleLangunit(payload) {
        const response = await AxiosIns().get(`dashboard/masters/langunit/${payload}`);
        return response;
    },

    async saveLangunit(payload) {
        const response = await AxiosIns().post(`dashboard/masters/langunit-save`, payload);
        return response;
    },

    async setLangunitStatus(payload) {
        const response = await AxiosIns().post(`dashboard/masters/langunit-status`, payload);
        return response;
    },
    // Langunit Masters End

    // Langformat Masters Start
    async getAllLangformat() {
        const response = await AxiosIns().get(`dashboard/masters/langformat`);
        return response;
    },

    async getSingleLangformat(payload) {
        const response = await AxiosIns().get(`dashboard/masters/langformat/${payload}`);
        return response;
    },

    async saveLangformat(payload) {
        const response = await AxiosIns().post(`dashboard/masters/langformat-save`, payload);
        return response;
    },

    async setLangformatStatus(payload) {
        const response = await AxiosIns().post(`dashboard/masters/langformat-status`, payload);
        return response;
    },
    // Langformat Masters End

    // Langcategory Masters Start
    async getAllLangcategory() {
        const response = await AxiosIns().get(`dashboard/masters/langcategory`);
        return response;
    },

    async getSingleLangcategory(payload) {
        const response = await AxiosIns().get(`dashboard/masters/langcategory/${payload}`);
        return response;
    },

    async saveLangcategory(payload) {
        const response = await AxiosIns().post(`dashboard/masters/langcategory-save`, payload);
        return response;
    },

    async setLangcategoryStatus(payload) {
        const response = await AxiosIns().post(`dashboard/masters/langcategory-status`, payload);
        return response;
    },
    // Langcategory Masters End

    // Category Masters Start
    async getAllCategory() {
        const response = await AxiosIns().get(`dashboard/masters/category`);
        return response;
    },

    async getSingleCategory(payload) {
        const response = await AxiosIns().get(`dashboard/masters/category/${payload}`);
        return response;
    },

    async saveCategory(payload) {
        const response = await AxiosIns().post(`dashboard/masters/category-save`, payload);
        return response;
    },

    async setCategoryStatus(payload) {
        const response = await AxiosIns().post(`dashboard/masters/category-status`, payload);
        return response;
    },
    // Category Masters End

    // Retailer Masters Start
    async getAllRetailer() {
        const response = await AxiosIns().get(`dashboard/masters/retailer`);
        return response;
    },

    async getSingleRetailer(payload) {
        const response = await AxiosIns().get(`dashboard/masters/retailer/${payload}`);
        return response;
    },

    async saveRetailer(payload) {
        const response = await AxiosIns().post(`dashboard/masters/retailer-save`, payload);
        return response;
    },

    async setRetailerStatus(payload) {
        const response = await AxiosIns().post(`dashboard/masters/retailer-status`, payload);
        return response;
    },
    // Retailer Masters End
    // Store Masters
    async getAllStore() {
        const response = await AxiosIns().get(`dashboard/masters/store`);
        return response;
    },
    async getSingleStore(payload) {
        const response = await AxiosIns().get(`dashboard/masters/store/${payload}`);
        return response;
    },

    async saveStore(payload) {
        const response = await AxiosIns().post(`dashboard/masters/store-save`, payload);
        return response;
    },

    async setStoreStatus(payload) {
        const response = await AxiosIns().post(`dashboard/masters/store-status`, payload);
        return response;
    },
};
export default MasterServices;