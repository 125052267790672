import AxiosIns from "./AxiosIns";

const FoodServices = {
  async getAllRecipeMaster() {
    const response = await AxiosIns().get(`dashboard/food/master-recipe`);
    return response;
  },

  async getSingleRecipeMaster(payload) {
    const response = await AxiosIns().get(`dashboard/food/master-recipe/${payload}`);
    return response;
  },

  async saveRecipeMaster(payload) {
    const response = await AxiosIns().post(`dashboard/food/master-recipe-save`, payload);
    return response;
  },

  async setRecipeMatserStatus(payload) {
    const response = await AxiosIns().post(`dashboard/food/master-recipe-status`, payload);
    return response;
  },

  // RecipeMain Masters Start
  async getAllRecipeMain(payload) {
    const response = await AxiosIns().get(`dashboard/food/main-recipe`, { params: payload });
    return response;
  },

  async getSingleRecipeMain(payload) {
    const response = await AxiosIns().get(`dashboard/food/main-recipe/${payload}`);
    return response;
  },

  async saveRecipeMain(payload) {
    const response = await AxiosIns().post(`dashboard/food/main-recipe-save`, payload);
    return response;
  },

  async setMainRecipeStatus(payload) {
    const response = await AxiosIns().post(`dashboard/food/main-recipe-status`, payload);
    return response;
  },
  // RecipeMain Masters End

  // Ingredient Masters Start
  async getAllIngredientMaster() {
    const response = await AxiosIns().get(`dashboard/food/master-ingredient`);
    return response;
  },

  async getSingleIngredientMaster(payload) {
    const response = await AxiosIns().get(`dashboard/food/master-ingredient/${payload}`);
    return response;
  },

  async saveIngredient(payload) {
    const response = await AxiosIns().post(`dashboard/food/master-ingredient-save`, payload);
    return response;
  },

  async setIngredientStatus(payload) {
    const response = await AxiosIns().post(`dashboard/food/master-ingredient-status`, payload);
    return response;
  },
  // Ingredient Masters End

  // Ingredient Main Masters Start
  async getAllIngredientMain() {
    const response = await AxiosIns().get(`dashboard/food/main-ingredient`);
    return response;
  },

  async getSingleIngredientMain(payload) {
    const response = await AxiosIns().get(`dashboard/food/main-ingredient/${payload}`);
    return response;
  },

  async saveIngredientMain(payload) {
    const response = await AxiosIns().post(`dashboard/food/main-ingredient-save`, payload);
    return response;
  },

  async setIngredientMainStatus(payload) {
    const response = await AxiosIns().post(`dashboard/food/main-ingredient-status`, payload);
    return response;
  },
  // Ingredient Main Masters End

  // Ingredient Category Masters Start
  async getAllIngredientCatMaster() {
    const response = await AxiosIns().get(`dashboard/food/ingredient-cat`);
    return response;
  },

  async getSingleIngredientCatMaster(payload) {
    const response = await AxiosIns().get(`dashboard/food/ingredient-cat/${payload}`);
    return response;
  },

  async saveIngredientCatMaster(payload) {
    const response = await AxiosIns().post(`dashboard/food/ingredient-cat-save`, payload);
    return response;
  },

  async setIngredientCatMasterStatus(payload) {
    const response = await AxiosIns().post(`dashboard/food/ingredient-cat-status`, payload);
    return response;
  },
  // Ingredient Category Masters End


  // Recipe Ingredient Masters Start
  async getAllRecipeIngredient() {
    const response = await AxiosIns().get(`dashboard/food/recipe-ingredient`);
    return response;
  },

  async getSingleRecipeIngredient(payload) {
    const response = await AxiosIns().get(`dashboard/food/recipe-ingredient/${payload}`);
    return response;
  },

  async saveRecipeIngredient(payload) {
    const response = await AxiosIns().post(`dashboard/food/recipe-ingredient-save`, payload);
    return response;
  },

  async setRecipeIngredientStatus(payload) {
    const response = await AxiosIns().post(`dashboard/food/recipe-ingredient-status`, payload);
    return response;
  },
  // Recipe Ingredient Masters End

  // Ingredient Item Masters Start
  async getAllIngredientItem() {
    const response = await AxiosIns().get(`dashboard/food/ingredient-item`);
    return response;
  },

  async getSingleIngredientItem(payload) {
    const response = await AxiosIns().get(`dashboard/food/ingredient-item/${payload}`);
    return response;
  },

  async saveIngredientItem(payload) {
    const response = await AxiosIns().post(`dashboard/food/ingredient-item-save`, payload);
    return response;
  },

  async setIngredientItemStatus(payload) {
    const response = await AxiosIns().post(`dashboard/food/ingredient-item-status`, payload);
    return response;
  },
  // Ingredient Item Masters End

  // Item Masters Start
  async getAllItem() {
    const response = await AxiosIns().get(`dashboard/food/item`);
    return response;
  },

  async getSingleItem(payload) {
    const response = await AxiosIns().get(`dashboard/food/item/${payload}`);
    return response;
  },

  async saveItem(payload) {
    const response = await AxiosIns().post(`dashboard/food/item-save`, payload);
    return response;
  },

  async setItemStatus(payload) {
    const response = await AxiosIns().post(`dashboard/food/item-status`, payload);
    return response;
  },
  // Item Masters End

  // Icon Start
  async getAllIcons(payload) {
    let response = await AxiosIns().post(`dashboard/food/icons`, payload);
    return response;
  },
  // Icon End

    // Action Start
    async getAllFoodActions(payload) {
      let response = await AxiosIns().get(`dashboard/food/actions`);
      return response;
    },
    // Action End

};

export default FoodServices;
