import axiosInstance from './AxiosIns';

export default {

    async getUserProfile() {

        let response = await axiosInstance().get(`/dashboard/user-profile`);

        return response;
    },

}