import Vue from "vue";
import axios from "axios";
import { TokenService } from "./StorageService";

var apiLoading = null;

export default () => {

  if (!apiLoading) apiLoading = Vue.$loading.show();

  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_BASEURL}`
  });

  let AUTH_TOKEN = TokenService.getAccessToken();
  if (AUTH_TOKEN) {
    instance.defaults.headers.common["authorization"] = `${AUTH_TOKEN}`;
  }

  instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

  instance.interceptors.response.use(
    function (response) {
      if (apiLoading) { apiLoading.hide(); apiLoading = null; }
      return response;
    },
    function (error) {
      if (apiLoading) { apiLoading.hide(); apiLoading = null; }
      if (!error["response"]) {
        showErrorMessage("Your authorization token is invalid or expired");
        // window.location.replace("/");
        return Promise.reject(error);
      } else if (error.response.status == 403) {
        showErrorMessage("Your authorization token is invalid or expired");
        // window.location.replace("/");
      }
      return Promise.reject(error.response);
    }
  );
  return instance;
};

const showErrorMessage = (message) => {
  console.log("Error ", message);
  // Show Some Notification
};
