import axiosInstance from './AxiosIns';

export default {

    async getAllUser() {

        let response = await axiosInstance().get(`dashboard/user/user`);

        return response;
    },

    async getSingleUser(payload) {
        let response = await axiosInstance().get(`/dashboard/user/user/${payload}`);

        return response;
    },

    async saveUser(payload) {

        let response = await axiosInstance().post(`/dashboard/user/user-save`, payload);

        return response;
    },

    async setUserStatus(payload) {

        let response = await axiosInstance().post(`/dashboard/user/user-status`, payload);

        return response;
    },

    async getAllRole() {

        let response = await axiosInstance().get(`dashboard/user/role`);

        return response;
    },

    async getSingleRole(payload) {
        const { role_id } = payload;

        let response = await axiosInstance().get(`/dashboard/user/role/${role_id}`);

        return response;
    },

    async saveRole(payload) {

        let response = await axiosInstance().post(`/dashboard/user/role-save`, payload);

        return response;
    },

    async setRoleStatus(payload) {

        let response = await axiosInstance().post(`/dashboard/user/role-status`, payload);

        return response;
    },

    async getSysModule() {

        let response = await axiosInstance().get(`/dashboard/user/sys-modules`);

        return response;
    },

}