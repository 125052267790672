import ability from "./ability";

export default function setAbilities(module_map) {

    if (module_map && module_map.length) {
        const ability_arr = [{
            action: "read",
            subject: "home"
        }];
        for (let i = 0; i < module_map.length; i++) {
            const map = module_map[i];
            if (map.access && map.access === "WRITE") {
                ability_arr.push({
                    action: 'read',
                    subject: map.module,
                });
                ability_arr.push({
                    action: 'write',
                    subject: map.module,
                });
            } else if (map.access && map.access === "READ") {
                ability_arr.push({
                    action: 'read',
                    subject: map.module,
                });
            }
            ability.update(ability_arr);
        }
    }
}