import ProfileServices from "@/apiServices/ProfileServices";
import UserServices from "@/apiServices/UserServices";
import ability from "@/libs/acl/ability";
import setAbilities from "@/libs/acl/setAbilities";

export default {
    namespaced: true,
    state: {
        user_authenticated: false,
        user_details: {
            user_first_name: "Firstname",
            user_last_name: "Lastname",
        },
        roles: [],
        sys_module: []
    },
    getters: {
        getUserDetails: state => state.user_details,
        getUserAuthenticated: state => state.user_authenticated,
        getRoles: state => state.roles,
        getSysModules: state => state.sys_module,
    },
    mutations: {
        SET_USER_AUTHENTICATED(state, val) {
            state.user_authenticated = val
        },
        SET_USER_DETAILS(state, val) {
            state.user_details = val
        },
        SET_ROLE_DETAILS(state, val) {
            state.roles = val
        },
        SET_SYS_MODULES(state, val) {
            state.sys_module = val
        },
    },
    actions: {
        setUserDetails: async ({ commit }) => {
            await commit('SET_USER_DETAILS', {});
            ProfileServices.getUserProfile()
                .then(async (response) => {
                    if (response.data.status) {
                        await commit('SET_USER_DETAILS', response.data.data);
                        setAbilities(response.data.data.module_map);
                    } else {
                        console.log(response.data);
                    }
                })
                .catch(err => {
                    console.log('Error in getting user details ', err);
                })
        },
        setRole: async ({ dispatch, commit, state }, payload) => {
            await commit('SET_ROLE_DETAILS', []);
            UserServices.getAllRole()
                .then(async (response) => {
                    if (response.data.status) {
                        await commit('SET_ROLE_DETAILS', response.data.data)
                    } else {
                        console.log(response.data);
                    }
                })
                .catch(err => {
                    console.log('Error in getting roles ', err);
                })
        },
        setSysModules: async ({ commit }) => {
            await commit('SET_SYS_MODULES', {});
            UserServices.getSysModule()
                .then(async (response) => {
                    if (response.data.status) {
                        await commit('SET_SYS_MODULES', response.data.data)
                    } else {
                        console.log(response.data);
                    }
                })
                .catch(err => {
                    console.log('Error in getting sys modules ', err);
                })
        },
    },
}