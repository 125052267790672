import i18n from '@/libs/i18n'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/pages/Authentication/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'Registration',
      component: () => import('@/views/pages/Authentication/Registration.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgotpassword',
      name: 'ForgotPassword',
      component: () => import('@/views/pages/Authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/resetpassword',
      name: 'ResetPassword',
      component: () => import('@/views/pages/Authentication/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/pages/Home/Home.vue'),
      meta: {
        pageTitle: 'Home',
        // breadcrumb: [
        //   {
        //     text: 'Home',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: '/food/recipe-list',
      name: 'recipe-list',
      component: () => import('@/views/pages/Food/RecipeMaster/RecipeList.vue'),
      meta: {
        pageTitle: 'RecipeMaster.Recipe Master List',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/food/recipe-form/:recipe_id',
      name: 'recipe-form',
      component: () => import('@/views/pages/Food/RecipeMaster/RecipeForm.vue'),
      meta: {
        pageTitle: 'RecipeMaster.Recipe Master List',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    {
      path: '/food/recipe-main-list',
      name: 'recipe-main-list',
      component: () => import('@/views/pages/Food/RecipeMain/RecipeMainList.vue'),
      meta: {
        pageTitle: 'RecipeMainMaster.RecipeMainMaster',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/food/recipe-main-form/:recipe_id',
      name: 'recipe-main-form',
      component: () => import('@/views/pages/Food/RecipeMain/RecipeMainForm.vue'),
      meta: {
        pageTitle: 'RecipeMainMaster.Recipe Main Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    {
      path: '/food/ingredient-list',
      name: 'ingredient-list',
      component: () => import('@/views/pages/Food/IngredientMaster/IngredientList.vue'),
      meta: {
        pageTitle: 'Ingredient Master List.IngredientMaster',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/food/ingredient-form/:mingredient_id',
      name: 'ingredient-form',
      component: () => import('@/views/pages/Food/IngredientMaster/IngredientForm.vue'),
      meta: {
        pageTitle: 'Ingredient Master List.Ingredient Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    {
      path: '/food/ingredient-main-list',
      name: 'ingredient-main-list',
      component: () => import('@/views/pages/Food/IngredientMain/IngredientMainList.vue'),
      meta: {
        pageTitle: 'IngredientMainMaster.Ingredient Form',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/food/ingredient-main-form/:ingredient_id',
      name: 'ingredient-main-form',
      component: () => import('@/views/pages/Food/IngredientMain/IngredientMainForm.vue'),
      meta: {
        pageTitle: 'IngredientMainMaster.Ingredient Main Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    {
      path: '/food/ingredient-cat-list',
      name: 'ingredient-cat-list',
      component: () => import('@/views/pages/Food/IngredientCatMaster/IngredientCatList.vue'),
      meta: {
        pageTitle: 'IngredientCategoryMaster.Ingredient Category Form',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/food/ingredient-cat-form/:ingcategory_id',
      name: 'ingredient-cat-form',
      component: () => import('@/views/pages/Food/IngredientCatMaster/IngredientCatForm.vue'),
      meta: {
        pageTitle: 'IngredientCategoryMaster.Ingredient Category Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/food/recipe-ingredient-list',
    //   name: 'recipe-ingredient-list',
    //   component: () => import('@/views/pages/Food/RecipeIngredient/RecipeIngredientList.vue'),
    //   meta: {
    //     pageTitle: 'RecipeIngredient.Recipe Ingredient List',
    //     breadcrumb: [
    //       {
    //         text: 'List',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/food/recipe-ingredient-form/:recingr_id',
    //   name: 'recipe-ingredient-form',
    //   component: () => import('@/views/pages/Food/RecipeIngredient/RecipeIngredientForm.vue'),
    //   meta: {
    //     pageTitle: 'RecipeIngredient.Recipe Ingredient Form',
    //     breadcrumb: [
    //       {
    //         text: 'Form',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/food/ingredient-item-list',
    //   name: 'ingredient-item-list',
    //   component: () => import('@/views/pages/Food/IngredientItem/IngredientItemList.vue'),
    //   meta: {
    //     pageTitle: 'Ingredient Item List.IngredientItemList',
    //     breadcrumb: [
    //       {
    //         text: 'List',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/food/ingredient-item-form/:ingritem_id',
    //   name: 'ingredient-item-form',
    //   component: () => import('@/views/pages/Food/IngredientItem/IngredientItemForm.vue'),
    //   meta: {
    //     pageTitle: 'Ingredient Item List.Ingredient Item Form',
    //     breadcrumb: [
    //       {
    //         text: 'Form',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/food/item-list',
    //   name: 'item-list',
    //   component: () => import('@/views/pages/Food/Item/ItemList.vue'),
    //   meta: {
    //     pageTitle: 'ItemList.Item List',
    //     breadcrumb: [
    //       {
    //         text: 'List',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/food/item-form/:item_id',
    //   name: 'item-form',
    //   component: () => import('@/views/pages/Food/Item/ItemForm.vue'),
    //   meta: {
    //     pageTitle: 'ItemList.Item Form',
    //     breadcrumb: [
    //       {
    //         text: 'Form',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    {
      path: '/masters/language-list',
      name: 'language-list',
      component: () => import('@/views/pages/Masters/LanguageMaster/LanguageList.vue'),
      meta: {
        pageTitle: 'Sidebar.Language Master List',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/language-form/:language_id',
      name: 'language-form',
      component: () => import('@/views/pages/Masters/LanguageMaster/LanguageForm.vue'),
      meta: {
        pageTitle: 'Sidebar.Language Master Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/lang-category-list',
      name: 'lang-category-list',
      component: () => import('@/views/pages/Masters/LangCategoryMaster/LangCategoryList.vue'),
      meta: {
        pageTitle: 'Sidebar.Language Category Master List',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/lang-category-form/:langcategory_id',
      name: 'lang-category-form',
      component: () => import('@/views/pages/Masters/LangCategoryMaster/LangCategoryForm.vue'),
      meta: {
        pageTitle: 'Sidebar.Language Category Master Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/country-list',
      name: 'country-list',
      component: () => import('@/views/pages/Masters/CountryMaster/CountryList.vue'),
      meta: {
        pageTitle: 'Sidebar.Country Master List',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/country-form/:country_id',
      name: 'country-form',
      component: () => import('@/views/pages/Masters/CountryMaster/CountryForm.vue'),
      meta: {
        pageTitle: 'Sidebar.Country Master Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/cuisine-list',
      name: 'cuisine-list',
      component: () => import('@/views/pages/Masters/CuisineMaster/CuisineList.vue'),
      meta: {
        pageTitle: 'Sidebar.Cuisine Master List',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/cuisine-form/:cuisine_id',
      name: 'cuisine-form',
      component: () => import('@/views/pages/Masters/CuisineMaster/CuisineForm.vue'),
      meta: {
        pageTitle: 'Sidebar.Cuisine Master Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/currency-list',
      name: 'currency-list',
      component: () => import('@/views/pages/Masters/CurrencyMaster/CurrencyList.vue'),
      meta: {
        pageTitle: 'Sidebar.Currency Master List',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/currency-form/:currency_id',
      name: 'currency-form',
      component: () => import('@/views/pages/Masters/CurrencyMaster/CurrencyForm.vue'),
      meta: {
        pageTitle: 'Sidebar.Currency Master Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/unit-list',
      name: 'unit-list',
      component: () => import('@/views/pages/Masters/UnitMaster/UnitList.vue'),
      meta: {
        pageTitle: 'Sidebar.Unit Master List',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/unit-form/:unit_id',
      name: 'unit-form',
      component: () => import('@/views/pages/Masters/UnitMaster/UnitForm.vue'),
      meta: {
        pageTitle: 'Sidebar.Unit Master Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/format-list',
      name: 'format-list',
      component: () => import('@/views/pages/Masters/FormatMaster/FormatList.vue'),
      meta: {
        pageTitle: 'Sidebar.Format Master List',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/format-form/:format_id',
      name: 'format-form',
      component: () => import('@/views/pages/Masters/FormatMaster/FormatForm.vue'),
      meta: {
        pageTitle: 'Sidebar.Format Master Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },

    {
      path: '/masters/langformat-list',
      name: 'langformat-list',
      component: () => import('@/views/pages/Masters/LangFormatMaster/LangFormatList.vue'),
      meta: {
        pageTitle: 'Sidebar.Language Format Master List',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/langformat-form/:langformat_id',
      name: 'langformat-form',
      component: () => import('@/views/pages/Masters/LangFormatMaster/LangFormatForm.vue'),
      meta: {
        pageTitle: 'Sidebar.Language Format Master Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/langunit-list',
      name: 'langunit-list',
      component: () => import('@/views/pages/Masters/LangUnitMaster/LangUnitList.vue'),
      meta: {
        pageTitle: 'Sidebar.Language Unit Master List',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/langunit-form/:langunit_id',
      name: 'langunit-form',
      component: () => import('@/views/pages/Masters/LangUnitMaster/LangUnitForm.vue'),
      meta: {
        pageTitle: 'Sidebar.Language Unit Master Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/category-list',
      name: 'category-list',
      component: () => import('@/views/pages/Masters/CategoryMaster/CategoryList.vue'),
      meta: {
        pageTitle: 'Sidebar.Category Master List',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/category-form/:category_id',
      name: 'category-form',
      component: () => import('@/views/pages/Masters/CategoryMaster/CategoryForm.vue'),
      meta: {
        pageTitle: 'Sidebar.Category Master Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/retailer-list',
      name: 'retailer-list',
      component: () => import('@/views/pages/Masters/RetailerMaster/RetailerList.vue'),
      meta: {
        pageTitle: 'Sidebar.Retailer Master List',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/retailer-form/:retailer_id',
      name: 'retailer-form',
      component: () => import('@/views/pages/Masters/RetailerMaster/RetailerForm.vue'),
      meta: {
        pageTitle: 'Sidebar.Retailer Master Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/store-list',
      name: 'store-list',
      component: () => import('@/views/pages/Masters/StoreMaster/StoreList.vue'),
      meta: {
        pageTitle: 'Sidebar.Store Master List',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/store-form/:store_id',
      name: 'store-form',
      component: () => import('@/views/pages/Masters/StoreMaster/StoreForm.vue'),
      meta: {
        pageTitle: 'Sidebar.Store Master Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    {
      path: '/website-users-list',
      name: 'website-users-list',
      component: () => import('@/views/pages/WebsiteUsers/WebsiteUsersList.vue'),
      meta: {
        pageTitle: 'Website Users',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/website-users-details/:user_id',
      name: 'website-users-details',
      component: () => import('@/views/pages/WebsiteUsers/WebsiteUserDetails.vue'),
      meta: {
        pageTitle: 'Website User Details',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/users/user-list',
      name: 'user-list',
      component: () => import('@/views/pages/Users/User/User.vue'),
      meta: {
        pageTitle: 'Sidebar.Users',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/user-form/:user_id',
      name: 'user-form',
      component: () => import('@/views/pages/Users/User/UserForm.vue'),
      meta: {
        pageTitle: 'Users Form',
        breadcrumb: [
          {
            text: 'Form',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/role',
      name: 'role-list',
      component: () => import('@/views/pages/Users/Role/Role.vue'),
      meta: {
        pageTitle: 'Roles',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/role-form/:role_id',
      name: 'role-form',
      component: () => import('@/views/pages/Users/Role/RoleForm.vue'),
      meta: {
        pageTitle: 'Role',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
});

export default router;
