import MasterServices from "@/apiServices/MasterServices";
import FoodServices from "@/apiServices/FoodServices";

export default {
  namespaced: true,
  state: {
    units_list: [],
    languages_list: [],
    lang_categorys_list: [],
    lang_formats_list: [],
    lang_units_list: [],
    currencies_list: [],
    categories_list: [],
    countries_list: [],
    cuisines_list: [],
    retailers_list: [],
    stores_list: [],
  },
  getters: {
    getAllUnit: (state) => state.units_list,
    getAllLanguage: (state) => state.languages_list,
    getAllLangCategory: (state) => state.lang_categorys_list,
    getAllLangFormat: (state) => state.lang_formats_list,
    getAllLangUnit: (state) => state.lang_units_list,
    getAllCurrency: (state) => state.currencies_list,
    getAllCategory: (state) => state.categories_list,
    getAllCountry: (state) => state.countries_list,
    getAllCuisine: (state) => state.cuisines_list,
    getAllRetailer: (state) => state.retailers_list,
    getAllStore: (state) => state.stores_list,
  },
  mutations: {
    SET_UNIT: (state, val) => {
      state.units_list = val;
    },
    SET_LANGUAGE: (state, val) => {
      state.languages_list = val;
    },
    SET_LANG_CATEGORY: (state, val) => {
      state.lang_categorys_list = val;
    },
    SET_LANG_FORMAT: (state, val) => {
      state.lang_formats_list = val;
    },
    SET_LANG_UNIT: (state, val) => {
      state.lang_units_list = val;
    },
    SET_CURRENCY: (state, val) => {
      state.currencies_list = val;
    },
    SET_CATEGORY: (state, val) => {
      state.categories_list = val;
    },
    SET_COUNTRY: (state, val) => {
      state.countries_list = val;
    },
    SET_CUISINE: (state, val) => {
      state.cuisines_list = val;
    },
    SET_RETAILER: (state, val) => {
      state.retailers_list = val;
    },
    SET_STORE: (state, val) => {
      state.stores_list = val;
    },
  },
  actions: {
    setUnit: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_UNIT", []);
      MasterServices.getAllUnit()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_UNIT", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting Unit", error);
        });
    },
    setLanguage: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_LANGUAGE", []);
      MasterServices.getAllLanguages()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_LANGUAGE", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting Language", error);
        });
    },
    setLangCategory: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_LANG_CATEGORY", []);
      MasterServices.getAllLangcategory()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_LANG_CATEGORY", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting LangCategory", error);
        });
    },
    setLangFormat: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_LANG_FORMAT", []);
      MasterServices.getAllLangformat()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_LANG_FORMAT", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting LangFormat", error);
        });
    },
    setLangUnit: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_LANG_UNIT", []);
      MasterServices.getAllLangunit()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_LANG_UNIT", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting LangUnit", error);
        });
    },
    setCurrency: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_CURRENCY", []);
      MasterServices.getAllCurrency()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_CURRENCY", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting Currency", error);
        });
    },
    setCategory: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_CATEGORY", []);
      MasterServices.getAllCategory()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_CATEGORY", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting Category", error);
        });
    },
    setCountry: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_COUNTRY", []);
      MasterServices.getAllCountry()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_COUNTRY", response.data.data);
          } else {
            console.log("setCountry status false " + response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting Country", error);
        });
    },
    setCuisine: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_CUISINE", []);
      MasterServices.getAllCuisine()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_CUISINE", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting Cuisine", error);
        });
    },
    setRetailer: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_RETAILER", []);
      MasterServices.getAllRetailer()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_RETAILER", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting Retailer", error);
        });
    },
    setStore: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_STORE", []);
      MasterServices.getAllStore()
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_STORE", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("Error in getting Store", error);
        });
    },
  },
};
