export default  {

    "LoginPage": {
        "Login": "Login!",
        "Onceyouget": "Once you get to know us, you cant stay away from us!",
        "Password": "Password",
        "name": "name",
        "ForgotPassword": "Forgot Password?",
        "Signin": "Sign in",
        "Createaccount": "Create an account"
    },

    "RecipeMaster":{
        "Recipe Id": "RECIPE ID",
        "Recipe Master List": "Recipe Master List",
        "Add Recipe":"Add Recipe",   
        "Recipe Name":"Recipe Name",
        "Recipe Master Form":"Recipe Master Form",
        "Recipe Code":"Recipe Code",
        "Cuisine Name":"Cuisine Name",
        "Country Name":"Country",
        "Recipe Description":"Recipe Description"
    },

    "RecipeMainMaster": {
        "RecipeMainMaster": "Recipe Main List",
        "Recipe Main Form": "Recipe Main Form",
        "RECIPEID": "RECIPE ID",
        "RECIPENAME": "RECIPE NAME",
        "AddIngredient":"Add Ingredient",
        "RECIPE CODE": "RECIPE CODE",
        "AddRecipe": "Add Recipe",
        "Recipe Name": "Recipe Name",
        "Recipe Code": "Recipe Code",
        "Recipe SS ID": "Recipe SS ID",
        "Select Recipe Master": "Select Recipe Master",
        "Language": "Language ",
        "Country": "Country",
        "Total Time": "Total Time",
        "Cook Time": "Cook Time",
        "Prep Time": "Prep Time",
        "Recipe Portions": "Recipe Portions",
        "Recipe Calories": "Recipe Calories",
        "Recipe Carb Content": "Recipe Carb Content",
        "Recipe Fat Content": "Recipe Fat Content",
        "Recipe Protein Content": "Recipe Protein Content",
        "Language Category": "Language Category",
        "Recipe Type": "Recipe Type",
        "Recipe Type 1": "Recipe Type 1",
        "Recipe Type 2": "Recipe Type 2",
        "Veg": "Veg",
        "Lactose Friendly": "Lactose Friendly",
        "Gluten Friendly": "Gluten Friendly",
        "Peanut Friendly": "Peanut Friendly",
        "Chicken": "Chicken",
        "Turkey": "Turkey",
        "Pork": "Pork",
        "Lamb": "Lamb",
        "Beef": "Beef",
        "Fish": "Fish",
        "Egg": "Egg",
        "Legume": "Legume",
        "Milk Heavy": "Milk Heavy",
        "Carb Heavy": "Carb Heavy",
        "Nuts Heavy": "Nuts Heavy",
        "Veggie Heavy": "Veggie Heavy",

        "Meat": "Meat",
        "Red Meat": "Red Meat",
        "Other Meat": "Other Meat",
        "Vegan": "Vegan",

        "SN":"SN",
        "Ingredient":"Ingredient",
        "Quantity":"Quantity",
        "Unit":"Unit",
        "Action":"Action"
    },
 
    "Ingredient Master List":{
        "IngredientMaster": "Ingredient Master List",
        "INGREDIENTID": "INGREDIENT ID",
        "INGREDIENTNAME": "INGREDIENT NAME",
        "INGREDIENTCODE": "INGREDIENT CODE",
        "Ingredient Form":"Ingredient Form",
        "AddIngredient":"Add Ingredient",      
        "Ingredient Name":"Ingredient Name",
        "Ingredient Code":"Ingredient Code",
        "Cuisine Name":"Cuisine Name",
        "Country Name":"Country Name",
        "Unit Name":"Unit Name",
        "Ingredient Description":"Ingredient Description"
    },

    "IngredientMainMaster":{
        "Ingredient Main Master":"Ingredient Main List",
        "IngredientMaster": "Ingredient Master List",
        "INGREDIENTID": "INGREDIENT ID",
        "INGREDIENTNAME": "INGREDIENT NAME",
        "INGREDIENTCODE": "INGREDIENT CODE",
        "Ingredient Form":"Ingredient Form",
        "Ingredient Form":"Ingredient Form",
        "Description":"Description",
        "Ingredient Category":"Ingredient Category",
        
        "Ingredient Main Form":"Ingredient Main Form",
        "Ingredient Main Name":"Ingredient Main Name",
        "Ingredient Code":"Ingredient Code",
        "Ingredient Name":"Ingredient Name",
        "Country Name":"Country",
        "Language Name":"Language",
        "AddIngredient":"Add Ingredient"
    },
   "RecipeIngredient":{
        "Recipe Ingredient List": "Recipe Ingredient List",
        "RECIPEINGREDIENTID": "RECIPE INGREDIENT ID",
        "MAININGREDIENTNAME": "INGREDIENT",
        "MAINRECIPE NAME": "MAIN RECIPE NAME",
        "LANGUNITNAME": "LANG. UNIT NAME",
        "UNITSQUANTITY":"UNITS QUANTITY",
        "Add Recipe Ingredient":"Add Recipe Ingredient",
        "Recipe Ingredient Form":"Recipe Ingredient Form",       
        "Main Ingredient Name":"Ingredient",
        "Main Recipe Name":"Main Recipe Name",
        "Language Unit Name":"Language Unit Name",
        "Units Quantity":"Units Quantity"
    },

    "RecipeIngredient": {
        
        "Recipe Ingredient List": "Recipe Ingredient List",
        "RECIPEINGREDIENTID": "RECIPE INGREDIENT ID",
        "MAININGREDIENTNAME": "MAIN INGREDIENT NAME",
        "MAINRECIPE NAME": "MAIN RECIPE NAME",
        "LANGUNITNAME": "LANG. UNIT NAME",
        "UNITSQUANTITY": "UNITS QUANTITY",
        "Add Recipe Ingredient": "Add Recipe Ingredient",
        "Recipe Ingredient Form": "Recipe Ingredient Form",
        "Main Ingredient Name": "Main Ingredient Name",
        "Main Recipe Name": "Main Recipe Name",
        "Language Unit Name": "Language Unit Name",
        "Units Quantity": "Units Quantity"
    },

    "Ingredient Item List": {
        "IngredientItemList": "Ingredient Item List",
        "INGREDIENT ITEM ID": "INGREDIENT ITEM ID",
        "ITEM NAME": "ITEM NAME",
        "MAIN INGREDIENT NAME": "MAIN INGREDIENT NAME",
        "LANG. UNIT NAME": "LANG. UNIT NAME",
        "UNITS QUANTITY": "UNITS QUANTITY",
        "STATUS": "STATUS",
        "Add Ingredient Item": "Add Ingredient Item",
        "Ingredient Item Form": "Ingredient Item Form",
        "Main Ingredient Name": "Ingredient",
        "Item Name": "Item Name",
        "Language Unit Name": "Language Unit Name",
        "Units Quantity": "Units Quantity"
    },

    "ItemList": {
        "Item List": "Item List",
        "ITEM ID": "ITEM ID",
        "ITEM NAME": "ITEM NAME",
        "ITEM URL": "ITEM URL",
        "Add Item": "Add Item",
        "Item Form": "Item Form",
        "Store Name": "Store Name",
        "Item Name": "Item Name",
        "Item Url": "Item Url",
        "Item Ean 13": "Item Ean 13",
        "Item Catalogref": "Item Catalogref",
        "Item SKU Id": "Item SKU Id",
        "Main Ingredient Name": "Main Ingredient Name",
        "Lang. Format": "Lang. Format",
        "Lang. Unit Name": "Lang. Unit Name",
        "Item Units Quantity": "Item Units Quantity",
        "Currency": "Currency",
        "Item Price": "Item Price"
    },

    "Ingredient Item List":{
        "IngredientItemList":"Ingredient Item List",
        "INGREDIENT ITEM ID": "INGREDIENT ITEM ID",
        "ITEM NAME": "ITEM NAME",
        "MAIN INGREDIENT NAME":"MAIN INGREDIENT NAME",
        "LANG. UNIT NAME": "LANG. UNIT NAME",
        "UNITS QUANTITY": "UNITS QUANTITY",
        "STATUS": "STATUS",
        "Add Ingredient Item":"Add Ingredient Item",
        "Ingredient Item Form":"Ingredient Item Form",
        "Item Name":"Item Name",
        "Language Unit Name":"Language Unit Name",
        "Units Quantity":"Units Quantity"
    },
    "ItemList":{ 
        "Item List":"Item List",
        "ITEM ID":"ITEM ID","ITEM NAME": "ITEM NAME",
        "ITEM URL":"ITEM URL",
        "Add Item":"Add Item",
        "Item Form":"Item Form",
        "Store Name":"Store Name",
        "Item Name":"Item Name",
        "Item Url":"Item Url",
        "Item Ean 13":"Item Ean 13",
        "Item Catalogref":"Item Catalogref",
        "Item SKU Id":"Item SKU Id",
        "Main Ingredient Name":"Main Ingredient Name",
        "Lang. Format":"Lang. Format",
        "Lang. Unit Name":"Lang. Unit Name",
        "Item Units Quantity":"Item Units Quantity",
        "Currency":"Currency",
        "Item Price":"Item Price"
    },



    "Language List":{
        "LANGUAGE ID": "LANGUAGE ID",
        "LANGUAGE":"LANGUAGE",
        "LANGUAGE CODE":"LANGUAGE CODE",
        "Add Lang":"Add Language",
        "Add Category":"Add Category",
        "Category Id":"Category Id",
        "Category Name":"Category Name",
        "Category Code":"Category Code",
        "Category Description":"Category Description"

    },
    "Country Master List":{
        "Country Master List":"Country List",
        "COUNTRY ID":"COUNTRY ID",
        "COUNTRY NAME":"COUNTRY NAME",
        "COUNTRY CODE":"COUNTRY CODE",
        "Country ISD Code":"Country ISD Code",
        "Add Country":"Add Country",
        "Country Name":"Country Name",
        "Country Code":"Country Code"
    },
    "Cuisine Master List":{
        "Cuisine Master List":"Cuisine Master List",
        "CUISINE ID":"CUISINE ID",
        "CUISINE NAME":"CUISINE NAME",
        "CUISINE CODE":"CUISINE CODE",
        "Add Cuisine":"Add Cuisine", 
        "Cuisine Name":"Cuisine Name",
        "Cuisine Code":"Cuisine Code",
        "Cuisine Description":"Cuisine Description"
    },
    "Currency Master List":{
        "CURRENCY ID": "CURRENCY ID",
        "CURRENCY CODE":"CURRENCY CODE",
        "Add Currency":"Add Currency",
        "Currency Name": "Currency",
        "Currency Code":"Currency Code",
        "Country Name":"Country Name"  
    },
    "Unit Master List":{
        "Unit Master List":"Unit Master List",
        "UNIT ID":"UNIT ID",
        "UNIT NAME":"UNIT NAME",
        "UNIT CODE":"UNIT CODE",
        "Add Unit":"Add Unit",
        "Unit Name":"Unit Name",
        "Unit Code":"Unit Code",
        "Unit Description":"Unit Description"
    },
    "Format Master List":{
        "Format Master List":"Format Master List",
        "FORMAT ID":"FORMAT ID",
        "FORMAT NAME":"FORMAT NAME",
        "FORMAT CODE":"FORMAT CODE",
        "Add Format":"Add Format",
        "Format Name":"Format Name",
        "Format Code":"Format Code",
        "Format Description":"Format Description"
    },
    "Language Format Master List":{
        "Language Format Master List":"Language Format Master List",
        "LANGUAGE FORMAT ID":"LANGUAGE FORMAT ID",
        "LANGUAGE FORMAT NAME":"LANGUAGE FORMAT NAME",
        "LANGUAGE FORMAT CODE":"LANGUAGE FORMAT CODE",
        "Add Lang":"Add LANGUAGE",
        "Language Format Name":"Language Format Name",
        "Language Format Code":"Language Format Code",
        "Language Name":"Language Name",
        "Language Format Description":"Language Format Description"
    },
    "Language Unit Master List":{
        "Language Unit Master": "Language Unit Master List",
        "LANGUAGE UNIT ID":"LANGUAGE UNIT ID",
        "LANGUAGE UNIT NAME":"LANGUAGE UNIT NAME",
        "LANGUAGE UNIT CODE":"LANGUAGE UNIT CODE",
        "Add Lang":"Add LANGUAGE",
        "Language Unit Name":"Language Unit Name",
        "Language Unit Code":"Language Unit Code",
        "Language Name":"Language Name",
        "Language Unit Description":"Language Unit Description"
    },
    "Category Master List":{
        "Category Master List":"Category Master List",
        "CATEGORY ID":"CATEGORY ID",
        "CATEGORY NAME":"CATEGORY NAME",
        "CATEGORY CODE":"CATEGORY CODE",
        "Add Category":"Add Category",
        "Category Name":"Category Name",
        "Category Code":"Category Code",
        "Category Description":"Category Description"
    },
    "Language Category Master List":{
        "Language Category Master List":"Language Category Master List",
        "LANGCATEGORY ID":"LANGCATEGORY ID",
        "LANGCATEGORY NAME":"LANGCATEGORY NAME",
        "LANGCATEGORY CODE":"LANGCATEGORY CODE",
        "Add LangCategory":"Add LangCategory",
        "LangCategory Name":"LangCategory Name",
        "LangCategory Code":"LangCategory Code",
        "Language Name":"Language Name",
        "LangCategory Description":"LangCategory Description"
    },
    "Retailer Master List":{
        "Retailer Master List":"Retailer Master List",
        "RETAILER ID":"RETAILER ID","RETAILER NAME":"RETAILER NAME",
        "RETAILER CODE":"RETAILER CODE",
        "Add Retailer":"Add Retailer",
        "Retailer Name":"Retailer Name",
        "Retailer Code":"Retailer Code",
        "Retailer URL":"Retailer URL"
    },
    "Store Master List":{
        "STORE ID":"STORE ID",
        "Store Master List":"Store Master List",
        "STORE NAME":"STORE NAME",
        "STORE CODE":"STORE CODE",
        "Add Store":"Add Store",
        "Store Name":"Store Name",
        "Store Code":"Store Code",
        "Retailer Name":"Retailer Name",
        "Country Name":"Country Name",
        "Store URL":"Store URL"
    },
    "UsersPage":{
        "NAME":"NAME",
        "EMAIL":"EMAIL",
        "ROLE":"ROLE",
        "MOBILE":"MOBILE",
        "CITY":"CITY",
        "USER NAME":"USER NAME",
        "ROLE KEY":"ROLE KEY"
    },
    "Common":{
        "Back":"Back",
        "Save":"Save",
        "Status":"STATUS",
        "Edit":"Action",
        "Search": "Search"
    },
    "IngredientCategoryMaster":{
        "AddIngredientCategory":"Add Ingredient Category",
        "INGREDIENTCATEGORYID":"INGREDIENT CATEGORYID",
        "INGREDIENTCATEGORYNAME":"INGREDIENT CATEGORY NAME",
        "INGREDIENTCATEGORYCODE":"INGREDIENT CATEGORY CODE",
        "Ingredient Category Name":"Ingredient Category Name",
        "Ingredient Category Code":"Ingredient Category Code",
        "Description":"Description",
        "Ingredient Category Form":"Ingredient Category Form"
    },

    "Sidebar":{
        "Home":"Home",
        "Food":"Food",
        "Recipe":"Recipe ",
        "RecipeMain":"Recipe Main ", 
        "Ingredient":"Ingredient ",
        "IngredientMain":"Ingredient Main ",
        "RecipeIngredient":"Recipe Ingredient",
        "IngredientItem":"Ingredient Item",
        "Item":"Item",
        "RecipeMaster":"Recipe Master",
        "Masters":"Masters",
        "IngredientCategory":"Ingredient Category",
        "Language Master List":"Language List",
        "Language Master Form":"Language Form",
        "Language Category Master List":"Language Category List",
        "Language Category Master Form":"Language Category Form",
        "Country Master List":"Country  List",
        "Country Master Form":"Country Form",
        "Cuisine Master List":"Cuisine  List",
        "Cuisine Master Form":"Cuisine Form",
        "Currency Master List":"Currency List",
        "Currency Master Form":"Currency Form",
        "Unit Master List":"Unit  List",
        "Unit Master Form":"Unit Form",
        "Format Master List":"Format List",
        "Format Master Form":"Format Form",
        "Language Format Master List":"Language Format List",
        "Language Format Master Form":"Language Format Form",
        "Language Unit Master List":"Language Unit List",
        "Language Unit Master Form":"Language Unit Form",
        "Category Master List":"Category List",
        "Category Master Form":"Category Form",
        "Retailer Master List":"Retailer List",
        "Retailer Master Form":"Retailer Form",
        "Store Master List":"Store List",
        "Store Master Form":"Store Form",
        "IngredientMaster":"Ingredient Master",
        "Language":"Language ",
        "Country":"Country ",
        "Cuisine":"Cuisine ",
        "Currency":"Currency ",
        "Unit":"Unit ",
        "Format":"Format ",
        "LanguageFormat":"Language Format ",
        "LanguageUnit":"Language Unit ",
        "Category":"Category ",
        "LanguageCategory":"Language Category ",
        "Retailer":"Retailer ",
        "Store":"Store ",
        "Users": "Users",
        "User":"User"
    },
    "img Upload":{
        "Upload a image of":"Upload a image of",
        "pixel":"pixel",
        "Upload Image":"Upload Image",
        "Delete":"Delete",
        "Crop":"Crop",
        "Upload":"Upload",
        "No File Choesen":"No File Choesen"
    }
}